
import Vue from "vue";
import Logo from "@/components/common/Logo.vue";
import BackButton from "@/components/common/BackButton.vue";
import { Actions, Mutations } from "@/store/models";
import { loadStripe } from '@stripe/stripe-js';
import {
  checkout,
  companies,
  IBillingDetails,
  IPaymentMethodTokenAddRequest,
  PaymentStatus,
} from "@/api";
import BillingSuccessfulDialog from "./BillingSuccessfulDialog.vue";

export default Vue.extend({
  name: "BillingEdit",
  components: { Logo, BackButton, BillingSuccessfulDialog },
  data() {
    return {
      image: require("/src/assets/Vertical-Marks-quarter-container.jpg"),
      balance: 0,
      number: "",
      expDate: "",
      cvc: "",
      token: "",
      stripePromise: null as any,
      cardElement: null as any,
      isCardElementFocused: null as any,
      cardTokenObj: null as any,
      card: null as any,
      errorMessage: null as string | null,
      isLoading: false,
      billingDetails: {
        name: "",
        address: {
          postal_code : null as any,
        },
      } as IBillingDetails,
      billingSuccessfulDialog: false,
    };
  },
  computed: {},
  created() {
    this.getBalance();
    this.getSavedPaymentMethod();
    this.initializeStripe();
  },
  methods: {
    async initializeStripe() {
      this.stripePromise = await loadStripe(process.env.VUE_APP_STRIPE_PK);

      const stripe = await this.stripePromise;
      if (stripe) {
        const elements = stripe.elements();
        const cardStyle = {
            base: {
              color: '#FFFFFF',
              fontFamily: '"Roboto", sans-serif',
              fontSmoothing: 'antialiased',
              fontWeight : 400,
              fontSize: '15px',
              '::placeholder': {
                  color: '#aab7c4',
              },
            },
            invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
            },
        };
        this.cardElement = elements.create('card', {style: cardStyle, hidePostalCode: true});
        this.cardElement.mount('#card-element');

        this.cardElement.on('focus', () => {
          this.isCardElementFocused = true;
        });
        this.cardElement.on('blur', () => {
          this.isCardElementFocused = false;
        });

        this.cardElement.on('change', (event: any) => {
          if (event.error) {
            this.errorMessage = event.error.message;
          } else {
            this.errorMessage = null;
          }
        });
      }
    },
    async getBalance() {
      try {
        this.balance = (
          await checkout.getOutstandingDues((this as any).companyId)
        ).data.total_due;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async getSavedPaymentMethod() {
      try {
        const response = (
          await checkout.getPaymentMethods(this.$store.state.company?.id)
        ).data.pop();
        if (response) {
          this.billingDetails = JSON.parse(response.billing_details);
          const card = JSON.parse(response.card);
          this.expDate =
            (card.exp_month < 10 ? `0${card.exp_month}` : card.exp_month) +
            "/" +
            card.exp_year;
        }
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async getPaymentMethod(): Promise<IPaymentMethodTokenAddRequest> {
      const stripe = await this.stripePromise;
      if (!stripe) {
        throw "Please enter correct payment information.";
      }

      this.isLoading = true;

      const { token, error } = await stripe.createToken(this.cardElement, {
        name: this.billingDetails.name,
        address_city: this.billingDetails.address.city,
        address_country: this.billingDetails.address.country,
        address_zip: this.billingDetails.address.postal_code,
      });

      if (error) {
        this.errorMessage = error.message ?? 'An unknown error occurred';
        this.isLoading = false;
      } else {
        this.card = {token : token.id};
        this.cardTokenObj = token;
        this.billingDetails.address.postal_code=token.card.address_zip;
        this.$emit('update:paymentMethod', {
          billing_details: this.billingDetails,
          card: {token : token.id}
        });
        this.$emit('save');
        this.isLoading = false;
      }

      const paymentMethod = {
        card: {
          token : token.id
        },
        billing_details: this.billingDetails,
      };
      const { card } = paymentMethod;
      if (!card || (!card.token)) {
        throw "Please enter correct payment information.";
      }
      if (
        !paymentMethod.billing_details?.name ||
        !paymentMethod.billing_details?.address?.country ||
        !paymentMethod.billing_details?.address?.city ||
        !paymentMethod.billing_details?.address?.postal_code
      ) {
        throw "Please enter correct payment information.";
      }
      return paymentMethod;
    },
    async savePaymentMethod() {
      try {
        const paymentMethod = await this.getPaymentMethod();
        // add payment method
        const paymentMethodResp = await checkout.addPaymentMethod(
          paymentMethod,
          (this as any).companyId
        );
        if (paymentMethodResp.data.error) {
          throw paymentMethodResp.data.error.message;
        }
        this.$store.dispatch(Actions.GetUserInfo);
        this.$store.dispatch(Actions.GetCompanyAccount);
        this.$store.commit(Mutations.SetToast, {
          text: "Billing updated successfully",
        });
        this.$router.go(-1);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async makePayment() {
      try {
        const paymentMethod = await this.getPaymentMethod();

        // add payment method
        const paymentMethodResp = await checkout.addPaymentMethod(
          paymentMethod,
          (this as any).companyId
        );
        if (paymentMethodResp.data.error) {
          throw paymentMethodResp.data.error.message;
        }

        // pay outstanding balance
        const response = (
          await checkout.payAllInvoiceDues((this as any).companyId)
        ).data;
        if (response[0].payment_status === PaymentStatus.Failed) {
          throw "Your card was declined. Please try again with a different payment card.";
        }

        // update company status if gssi admin
        if ((this as any).isGssiAdmin) {
          const company = (await companies.get((this as any).companyId))
            .data[0];
          this.$store.dispatch(Actions.GetCompany, company);
        } else {
          this.$store.dispatch(Actions.GetUserInfo);
          this.$store.dispatch(Actions.GetCompanyAccount);
        }

        // display dialog
        this.billingSuccessfulDialog = true;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
