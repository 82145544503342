
import Vue from "vue";

export default Vue.extend({
  name: "BillingSuccessfulDialog",
  components: {},
  props: {
    dialog: Boolean,
    balance: Number,
  },
  data() {
    return {
      order: {},
    };
  },
  computed: {},
  methods: {
    close() {
      this.$router.replace({ path: "accountmanagement" });
      this.$emit("update:dialog", false);
    },
    downloadReceipt() {
      return;
    },
    transferOwnership() {
      this.$router.replace({
        path: "accountmanagement",
        query: { transferownership: "true" },
      });
    },
  },
  watch: {},
});
